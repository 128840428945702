import CryptoJS from "crypto-js";
import placeHolderLogo from "../images/logo_placeholder.jpg";
import placeHolderCover from "../images/server_generic_cover.jpg";
import { useAppState, Steps } from "../state";
import { Configuration, OpenAIApi } from "openai";

const API_URL =
  process.env.REACT_APP_DCX_API_URL ||
  "https://console.drivebusinessintelligence.com";
let clientDataID = "0000";

function getClientAPI() {
  return "jI4MTE1YmM2ZGJlMzZjN2ZmMmY4OGM4ZGJjNjky=";
}

export function generateSignature(): string {
  const clientsAPIKey = getClientAPI();
  const date = new Date().toISOString().substring(0, 10);
  const signature = CryptoJS.MD5(clientsAPIKey + date).toString(
    CryptoJS.enc.Hex
  );
  return signature;
}

function localSignature() {
  const dcxLocalClient = JSON.parse(
    localStorage.getItem("DCXCliSig") || "null"
  );
  let existingSignature = null;

  if (dcxLocalClient !== null) {
    existingSignature = dcxLocalClient.signature;

    if (existingSignature !== null) {
      return existingSignature;
    } else {
      existingSignature = generateSignature();
    }

    dcxLocalClient.signature = existingSignature;
    localStorage.setItem("DCXCliSig", JSON.stringify(dcxLocalClient));
  } else {
    existingSignature = generateSignature();
    localStorage.setItem(
      "DCXCliSig",
      JSON.stringify({ signature: existingSignature })
    );
  }
  return existingSignature;
}

export function ClientCoverImage(urlClientID: string): string {
  const imageUrl = `${API_URL}/setup/stream_page_logo.php?PAGE=eec_weekly&ID=${urlClientID}`;
  const placeHolder = placeHolderCover;
  return imageUrl || placeHolder;
}

export function ClientLogoImage(urlClientID: string): string {
  const imageUrl = `${API_URL}/setup/stream_logo.php?PAGE=eec_weekly&ID=${urlClientID}`;
  const placeHolder = placeHolderCover;
  return imageUrl || placeHolder;
}

export async function ClientFetch(urlClientID: string): Promise<any> {
  clientDataID = urlClientID;
  const postData = {
    ApiURL: API_URL,
    ApiEndpoint: "api/client/fetch_account_info",
    ClientId: urlClientID,
    Signature: await localSignature()
  };
  const reqURL = `${postData.ApiURL}/${postData.ApiEndpoint}?ClientID=${postData.ClientId}&Signature=${postData.Signature}`;

  const data = await fetchData(postData, reqURL);
  return data;
}

export async function ClientEmployeesFetch(
  urlClientID: string,
  urlLocationID: string
): Promise<any> {
  try {
    const postData = {
      ApiURL: API_URL,
      ApiEndpoint: "api/client/fetch_employees",
      ClientId: urlClientID,
      LocationId: urlLocationID,
      Signature: await generateSignature()
    };
    const reqURL = `${API_URL}/${postData.ApiEndpoint}?ClientID=${postData.ClientId}&LocationID=${postData.LocationId}&Signature=${postData.Signature}`;
    console.log("Client Employees URL:", reqURL);
    const response = await fetch(reqURL, { method: "POST" });
    const data = await response.json();
    console.log("Client Employees:", data);
    return data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function RecordVisit(
  clientID: string,
  locationID: string,
  dcxClientCustomerID: string
): Promise<any> {
  const postData = {
    ApiURL: API_URL,
    ApiEndpoint: "api/client/record_visit",
    ClientId: clientID,
    LocationID: locationID,
    Signature: generateSignature(),
    ClientCustomerID: dcxClientCustomerID,
    VisitType: "In Store"
  };
  const recordVisitUrl = `${postData.ApiURL}/${postData.ApiEndpoint}?ClientID=${postData.ClientId}&LocationID=${postData.LocationID}&Signature=${postData.Signature}&ClientCustomerId=${postData.ClientCustomerID}&VisitType=${postData.VisitType}`;
  const response = await fetch(recordVisitUrl, { method: "POST" });
  const Visit = await response.json();
  console.log(
    Visit.Success
      ? `New Visit ID: ${Visit.VisitID}`
      : `Unable to create Visit: ${Visit.ErrorMessage}`
  );
  return response;
}

async function fetchData(postData: any, reqURL: string): Promise<any> {
  try {
    const response = await fetch(reqURL, { method: "POST" });
    const { Client } = await response.json();
    return Client;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function RecordDCXCustomer(
  clientID: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  locationID: string
): Promise<any> {
  const postData = {
    ApiURL: API_URL,
    ApiEndpoint: "api/survey/record_customer",
    ClientId: clientID,
    Signature: localSignature(),
    FirstName: firstName,
    LastName: lastName,
    LocationID: locationID,
    Email: email,
    Phone: phone
  };
  const recordCustomerUrl = `${postData.ApiURL}/${postData.ApiEndpoint}?ClientID=${postData.ClientId}&LocationID=${postData.LocationID}&FirstName=${postData.FirstName}&LastName=${postData.LastName}&Email=${postData.Email}&Phone=${postData.Phone}&Signature=${postData.Signature}`;
  const response = await recordNewCustomer(recordCustomerUrl, postData);
  console.log(await response);
  return response;
}

async function recordNewCustomer(url: string, postData: any): Promise<any> {
  try {
    const response = await fetch(url, { method: "POST" });
    const Client = await response.json();
    console.log(
      Client.Success
        ? `New client's customer created ID: ${Client.ClientCustomerID}`
        : `Unable to create customer: ${Client.ErrorMessage}`
    );
    return Client.ClientCustomerID;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function SendCCard(
  urlClientID: string,
  locationID: string,
  clientCustomerID: string,
  phone: string
): Promise<any> {
  const signature = localSignature();
  const sendCCardURL = `${API_URL}/api/survey/send_link_to_full_survey?ClientID=${urlClientID}&Signature=${signature}&LocationID=${locationID}&ClientCustomerID=${clientCustomerID}&Phone=${phone}`;
  try {
    console.log("Trying to send", sendCCardURL);
    const response = await fetch(sendCCardURL, { method: "POST" });
    const { Client } = await response.json();
    return Client;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function GetClientAPIK(urlClientID: string): Promise<any> {
  const postData = {
    ApiURL: API_URL,
    ApiEndpoint: "/api/register/fetch_api_key",
    ClientId: urlClientID,
    Signature: localSignature()
  };
  const requestUrl = `${postData.ApiURL}/${postData.ApiEndpoint}?ClientID=${postData.ClientId}&Signature=${postData.Signature}`;
  try {
    const response = await fetch(requestUrl, { method: "POST" });
    const { Client } = await response.json();
    return Client;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export function defaultClientData(): Record<string, string> {
  return {
    logoURL: placeHolderLogo,
    coverURL: placeHolderCover,
    clientID: clientDataID,
    locationID: "",
    dcxClientName: "dcxClientName",
    textTitle: "Who am I helping today?",
    textTitle2: "We can help!",
    textSubTitle: "Who we are helping today?",
    textSubTitle2: "Let's Talk!"
  };
}

export async function aiDeptChoose(helPreq: string) {
  console.log("AI Dept Choose:", helPreq);
  return GetAIDepartment(helPreq);
}

async function GetAIDepartment(searchRequest: string): Promise<any> {
  const configuration = new Configuration({
    apiKey: "sk-proj-JXKltVL3MfXWj01T80qXT3BlbkFJL38PdYqgC3aAkZUbBy8b"
  });
  const openai = new OpenAIApi(configuration);
  let hostServerURL = window.location.hostname;
  hostServerURL === "localhost"
    ? (hostServerURL = "http://localhost:8081")
    : (hostServerURL = "https://" + window.location.hostname);
  const url = new URL(window.location.href);
  const urlClientID = url.searchParams.get("ClientID") as string;
  const urlLocationID = url.searchParams.get("LocationID") as string;
  const departmentsListURL = `${hostServerURL}/departments?clientId=${encodeURIComponent(
    urlClientID
  )}&locationId=${encodeURIComponent(urlLocationID)}`;
  const chatGPTUrl = `${hostServerURL}/chatgpt`;
  let clientDepartmentsNames = "";
  let aiSuggestedDepartment = "";
  let clientNaicsCode = "";
  let searchQuery = "";

  try {
    const response = await fetch(departmentsListURL, { method: "GET" });
    if (response.ok) {
      let clientDepartmentsData = await response.json();
      console.log("Client Departments:", await clientDepartmentsData);
      if ((await clientDepartmentsData.data.length) <= 0) {
        console.log("No departments found for this client");
        const departmentsListURL = `${hostServerURL}/departments?clientId=9999&locationId=9999`;
        const response = await fetch(departmentsListURL, { method: "GET" });
        if (response.ok) {
          clientDepartmentsData = await response.json();
        } else {
          throw new Error("Request failed");
        }
      }
      clientDepartmentsNames = clientDepartmentsData.data.map(
        (item: { dptName: any }) => item.dptName
      );
      clientNaicsCode = clientDepartmentsData.data[0]["naicsCode"];
    } else {
      throw new Error("Request failed");
    }
  } catch (error) {
    console.error(error);
  }

  if (clientNaicsCode != "") {
    searchQuery = `A customer in a retail store asked the following: Where can I find a: ${searchRequest}. The store only has the departments associated with this NAICS code: ${clientNaicsCode}. Responding with only the relevant department name and no other text, which department in the store should the question be routed to? If the request cannot be associated with a department, please choose one of the following: ${clientDepartmentsNames}`;
  } else {
    searchQuery = `A customer in a retail store asked the following: Where can I find a: ${searchRequest}. The store has the following, and only, these departments: ${clientDepartmentsNames}. Which department should the question be routed to? Respond with only the correct department name from the list, no other text.`;
  }
  try {
    const { data } = await openai.createCompletion({
      model: "gpt-3.5-turbo-instruct",
      prompt: searchQuery,
      temperature: 0,
      max_tokens: 64,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0
    });

    if (
      data.choices &&
      data.choices.length > 0 &&
      data.choices[0].text !== undefined
    ) {
      aiSuggestedDepartment = data.choices[0].text.trim();
    }
  } catch (error) {
    console.error(error);
  } finally {
    const response = await fetch(chatGPTUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        requestText: searchQuery,
        responseText: aiSuggestedDepartment
      })
    });
    const result = await response.json();
    console.log("Save result:", result);
    return aiSuggestedDepartment;
  }
}

export async function createDBUser(dcxClientCustomer: {
  dcxCustomerId: any;
  name: string;
  email: string;
  phone: string;
}) {
  const newUserUrl = `/new-user`;

  try {
    const response = await fetch(newUserUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dcxClientCustomer)
    });

    const appUser = await response.json();

    if (appUser.errors) {
      console.warn("AppUser DB Not created");
    } else {
      console.log("MySQL Created User: ", appUser);
      return appUser;
    }
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function updateDBUser(userIdentifiers: {
  userId: any;
  dcxId: string;
}) {
  const updateUserUrl = `${process.env.REACT_APP_LOCALHOST}/update-user`;

  try {
    const response = await fetch(updateUserUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userIdentifiers)
    });

    const appUser = await response.json();

    if (appUser.errors) {
      console.log("Error: App User Not updated");
    } else {
      console.log("MySQL DcxId User Updated with", userIdentifiers.dcxId);
    }

    return appUser;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function getDBUser(phone: string): Promise<any> {
  const requestUrl = `/get-user?Phone=${phone}`;
  try {
    const response = await fetch(requestUrl, { method: "POST" });
    const getUser = await response.json();
    return getUser.appUser;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export async function saveDBHRequest(data: any) {
  try {
    const response = await fetch("/new-help", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ hRequest: data })
    });
    const result = await response.json();
    console.log("Save result:", result);
    return result;
  } catch (error) {
    console.error("Error saving HRequest:", error);
  }
}

export async function getDBHRequest(hrId: any): Promise<any> {
  const requestUrl = `/get-help?hrid=${hrId}`;
  try {
    const response = await fetch(requestUrl, { method: "POST" });
    const helpRequest = await response.json();
    return helpRequest;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
