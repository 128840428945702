import React, { useEffect, useRef, useState } from "react";
import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import { Steps, useAppState } from "../../state";
import {
  SendCCard,
  RecordVisit,
  generateSignature
} from "../../data/ClientData";

export interface CallMeProps {
  name: string;
  email: string;
  phone: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

const CallMEScreen: React.FC<CallMeProps> = props => {
  const {
    coverURL,
    clientID,
    locationID,
    customerDCXId,
    customerPhone,
    hBoxRequest,
    hRequestId,
    suggestedDepartment
  } = useAppState();
  const { name, phone } = props;
  const customerNumber = phone.replace(
    /^(\d{1})(\d{3})(\d{3})(\d{4})$/,
    "$1-$2-$3-$4"
  );

  const API_URL =
    process.env.REACT_APP_DCX_API_URL ||
    "https://dev.drivebusinessintelligence.com";

  let phone_number = null;
  let demoNumber = null;
  let employeeName = "";
  let employeeImage = "";

  function getEmployee() {
    const headers: Headers = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    const request: RequestInfo = new Request(
      `${API_URL}/api/client/fetch_employees?ClientID=${clientID}&LocationID=${locationID}&Signature=${generateSignature()}`,
      {
        method: "GET",
        headers: headers
      }
    );
    console.log(request);
    fetch(request)
      .then(response => response.json())
      .then(response => {
        for (let i = 0; i < response.length; i++) {
          if (response[i].Department === suggestedDepartment) {
            for (let j = 0; j < response[i].Notifications.length; j++) {
              if (
                response[i].Notifications[j].LocationID === locationID &&
                response[i].Notifications[j].NotificationsEnabled === "1"
              ) {
                employeeName = response[i].FirstName;
                employeeImage =
                  "https://ukmars.org/ukmars/wp-content/uploads/2020/07/pexels-photo-507410.jpeg";
                phone_number = response[i].Phone;
                break;
              }
            }
            break;
          }
        }
        if (employeeName === "") {
          for (let i = 0; i < response.length; i++) {
            for (let j = 0; j < response[i].Notifications.length; j++) {
              if (
                response[i].Notifications[j].LocationID === locationID &&
                response[i].Notifications[j].NotificationsEnabled === "1"
              ) {
                employeeName = response[i].FirstName;
                employeeImage =
                  "https://ukmars.org/ukmars/wp-content/uploads/2020/07/pexels-photo-507410.jpeg";
                phone_number = response[i].Phone;
                break;
              }
            }
            if (employeeName !== "") {
              break;
            }
          }
        }
        if (employeeName === "") {
          employeeName = response[0].FirstName;
          employeeImage =
            "https://ukmars.org/ukmars/wp-content/uploads/2020/07/pexels-photo-507410.jpeg";
          phone_number = response[0].Phone;
        }
      });
  }

  getEmployee();

  const employeeMessage = `Phone Help Request: ${hRequestId} Tel:${customerNumber}`;
  let hostServerURL =
    process.env.REACT_APP_LOCALHOST ||
    "https://dcxcommunications.herokuapp.com/";
  let params = new URLSearchParams(document.location.search);
  console.log(employeeName);
  console.log(employeeImage);
  console.log(phone_number);
  // if (phone_number != null) {
  //   demoNumber = phone_number;
  // } else {
  demoNumber = params.get("demo") as string;
  // }
  console.log("demoNumber", demoNumber);
  let serviceURL = `${hostServerURL}/sms-callme?message=${encodeURIComponent(
    employeeMessage
  )}&surl=${encodeURIComponent(hostServerURL)}`;
  if (demoNumber) {
    serviceURL = `${hostServerURL}/sms-callme?message=${encodeURIComponent(
      employeeMessage
    )}&surl=${encodeURIComponent(hostServerURL)}&demoNumber=${demoNumber}`;
  }

  const goHome = () => {
    const DCXCLIENT = localStorage.getItem("DCXCLIENT");
    if (DCXCLIENT !== null) {
      const localServiceURL = JSON.parse(DCXCLIENT).serviceURL;
      window.location.assign(`/${localServiceURL}`);
    } else {
      console.error("DCXCLIENT Service URL not found in localStorage");
    }
  };

  const [showButton, setShowButton] = useState(false);

  const useStyles = makeStyles((theme: Theme) => ({
    textSide: {
      color: "white",
      padding: "0.6em",
      fontWeight: "normal",
      fontSize: "1.4em"
    },
    centrado: {
      /* margin: '20px', */
      border: "1px solid"
    },
    joinButtons: {
      marginTop: "2px"
    },
    customerBgContainer: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "1em",
      backgroundImage: `url(${coverURL})`,
      backgroundSize: "cover",
      width: "300px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "20vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top"
      }
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    const callNotify = async () => {
      try {
        const response = await fetch(serviceURL, { method: "POST" });
        if (response.ok) {
          const data = await response.json();
          console.log("Response:", data);
        } else {
          throw new Error("Request failed");
        }
      } catch (error) {
        console.error(error);
      }
    };
    callNotify();

    setTimeout(function() {
      RecordVisit(clientID, locationID, customerDCXId);
      SendCCard(clientID, locationID, customerDCXId, customerPhone);
      setShowButton(true);
    }, 300000);
  }, [
    clientID,
    customerDCXId,
    customerNumber,
    customerPhone,
    employeeMessage,
    hBoxRequest,
    locationID,
    serviceURL
  ]);

  return (
    <div className="centrado">
      {<div className={classes.customerBgContainer}></div>}
      <br />
      <Typography variant="h6">Hi {name},</Typography>
      <Typography variant="h6">Your help request is sent!</Typography>
      <br />
      <img
        src={
          employeeImage
            ? employeeImage
            : "../../../images/Generic-Profile-image.png"
        }
      ></img>
      <Typography variant="subtitle1">
        {suggestedDepartment && employeeName
          ? `${employeeName} from the ${suggestedDepartment} department will call you back at ${customerNumber}`
          : `We'll call you back at ${customerNumber}`}{" "}
      </Typography>
      <br />

      {showButton && (
        <Button onClick={goHome} color="primary" variant="outlined" autoFocus>
          {" "}
          New Help Request{" "}
        </Button>
      )}
    </div>
  );
};
export default CallMEScreen;
